import { getCurrentYear } from "../../../utils/utils";
import { BRAND_NAME } from "../../../constants/const";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
export const Footer = () => {
  const year = getCurrentYear();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <footer className="footer -type-4 bg-light-9 pt-60">
      <div className="container">
        <div className="row y-gap-30 pb-60">
          <div className="col-xl-4 col-lg-5 col-md-6">
            {/* <div className="footer-header__logo"> */}
            <Link
              className="navbar-brand page-scroll p-0 remove-line"
              to="/"
              onClick={() => scrollToTop()}
            >
              <div
                className="footer-header__logo"
                style={{
                  cursor: "pointer",
                  display: "ruby-text",
                  marginTop: "-5px",
                }}
              >
                <img
                  src="img/general/logo-dark.png"
                  alt="logo"
                  className="size-30"
                />
                <span className="text-18 lh-1 text-dark-2 fw-800 pl-5 normal ff-raleway">
                  <img
                    src="img/general/logo_line_1.png"
                    alt=""
                    className="logo-img"
                  />
                </span>
              </div>
            </Link>{" "}
            {/* </div> */}
            <div className="mt-30 pr-20">
              <br />
              <div className="lh-17">
                At WashWoosh, we believe your vehicle deserves the best. As a
                premier car maintenance company, we specialize in transforming
                and maintaining your vehicle inside and out, ensuring it looks
                and feels brand new.
              </div>
              <div className="footer-header-socials mt-30">
                <div className="footer-header-socials__list d-flex items-center">
                  <Link
                    to="https://www.youtube.com/@WashWooshIndia"
                    target="_blank"
                    className="size-40 d-flex justify-center items-center"
                  >
                    {" "}
                    <img
                      src="img/general/youtube.svg"
                      alt="logo"
                      className="size-30"
                    />
                  </Link>
                  <Link
                    to="https://www.facebook.com/washwooshIndia/"
                    target="_blank"
                    className="size-40 d-flex justify-center items-center"
                  >
                    {" "}
                    <img
                      src="img/general/youtube (1).svg"
                      alt="logo"
                      className="size-30"
                    />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/washwooshindia/"
                    target="_blank"
                    className="size-40 d-flex justify-center items-center"
                  >
                    {" "}
                    <img
                      src="img/general/youtube (2).svg"
                      alt="logo"
                      className="size-30"
                    />
                  </Link>
                  <Link
                    to="https://www.instagram.com/washwooshindia/"
                    target="_blank"
                    className="size-40 d-flex justify-center items-center"
                  >
                    {" "}
                    <img
                      src="img/general/youtube (3).svg"
                      alt="logo"
                      className="size-30"
                    />
                  </Link>
                  <Link
                    to="https://x.com/washwooshindia"
                    target="_blank"
                    className="size-40 d-flex justify-center items-center"
                  >
                    <FontAwesomeIcon
                      icon={faXTwitter}
                      className="size-30"
                      style={{ color: "#7ac1c1" }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-md-6">
            <div className="text-17 fw-500 text-dark-1 uppercase mb-25">
              ABOUT
            </div>
            <div className="d-flex y-gap-10 flex-column">
              <Link to="/help" className="remove-line">
                Help
              </Link>
              <Link to="/terms" className="remove-line">
                Terms of Use
              </Link>
              <Link to="/privacy-policy" className="remove-line">
                Privacy Policy
              </Link>
              <Link to="/refund-policy" className="remove-line">
                Refund Policy
              </Link>
            </div>
          </div>
          <div className="col-xl-4 offset-xl-1 col-lg-3 col-md-6">
            <div className="text-17 fw-500 text-dark-1 uppercase mb-25">
              ABOUT PAYMENT
            </div>
            <div className="">
              <div className="text-17 text-dark-1 mb-2">
                Accepted Payment Methods:
              </div>
              <div className="footer-header-socials">
                <div className="footer-header-socials__list d-flex items-center">
                  <Link
                    to="#"
                    className="size-40 d-flex justify-center items-center"
                  >
                    <img
                      src="img/general/mastercard.svg"
                      alt="logo"
                      className="size-30"
                    />
                  </Link>
                  <Link
                    to="#"
                    className="size-40 d-flex justify-center items-center"
                  >
                    <img
                      src="img/general/visa.svg"
                      alt="logo"
                      className="size-30"
                    />
                  </Link>
                  <Link
                    to="#"
                    className="size-40 d-flex justify-center items-center"
                  >
                    <FontAwesomeIcon icon={faRupeeSign} color="#7ac1c1" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="pr-20 mt-10">
              <div className="lh-17">
                For all Tax purposes in the India ,our GST Number is
                06AAEFW9089K1ZE.
              </div>
            </div>
          </div>
        </div>

        <div className="py-30 border-top-light">
          <div className="row justify-between items-center y-gap-20">
            <div className="col-auto">
              <div className="footer-footer__copyright d-flex items-center h-100">
                © {year} {BRAND_NAME}. All Right Reserved.
              </div>
            </div>
          </div>

          <div className="sidemaintend">
           <span> A Unit of {" "}  
             <a
              style={{ color: "white", textDecoration:"none" }}
              href="https://satellitekeyit.com/"
              target="_blank"
              rel="noopener noreferrer"

            >
               <strong className="side" >SatelliteKey IT Solutions Pvt Ltd</strong>
            </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};
